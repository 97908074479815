body,
html {
  /* background: url("./images/background.png") #161b29; */
  background-position: top center;
  background-size: cover;
  color: #161b29;
  font-family: "Press Start 2P", cursive;
  font-weight: 400;
  margin: 0;
  padding: 0;
}

body,
button,
html,
input {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  font-size: 12px;
  font-weight: 400;

  @media only screen and (min-width: 768px) {
    font-size: 15px;
  }

  @media only screen and (min-width: 1400px) {
    font-size: 15px;
  }
}

*:focus {
  outline: none;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Press Start 2P", cursive;
  font-size: 20px;
  letter-spacing: 0.1em;
  line-height: 2em;
  margin: 0;
  margin-bottom: 2classname= "button" 0px;
}

/**
 * until https://github.com/facebook/create-react-app/issues/11771
 * gets fixed, let's hide the problem and not address it
 */
body
  > iframe[style*="2147483647"]:not([id="webpack-dev-server-client-overlay"]) {
  display: none;
}

h1 {
  font-size: 24px;

  @media only screen and (min-width: 768px) {
    font-size: 48px;
  }
}

h2 {
  font-size: 28px;

  @media only screen and (min-width: 768px) {
    font-size: 36px;
  }
}

span {
  display: inline;
}

strong {
  font-weight: 800;
}

.button {
  align-items: center;
  background: #0b843c;
  border: 1px solid #0b843c;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 15px;
  font-weight: 600;
  height: 43px;
  padding: 0 20px;

  @media only screen and (min-width: 768px) {
    font-size: 10px;
  }
}

.button:hover {
  background: #0b843c;
  border: 1px solid #0b843c;
  color: #fff;
}

.button-secondary {
  align-items: center;
  background: transparent;
  border: 1px solid #0b843c;
  border-radius: 4px;
  color: #0b843c !important;
  cursor: pointer;
  display: flex;
  font-size: 15px;
  font-weight: 600;
  height: 43px;
  padding: 0 20px;

  @media only screen and (min-width: 768px) {
    font-size: 10px;
  }
}

.button-secondary:hover {
  background: rgba(165, 70, 29, 0.2);
  border: 1px solid #0b843c;
  color: #0b843c !important;
}

.chakra-ui-light {
  background: transparent;
}

.wallet-adapter-modal-title {
  color: #fff;
}
